import { render, staticRenderFns } from "./verifikasiKasubag.vue?vue&type=template&id=45def388&scoped=true&"
import script from "./verifikasiKasubag.vue?vue&type=script&setup=true&lang=js&"
export * from "./verifikasiKasubag.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45def388",
  null
  
)

export default component.exports